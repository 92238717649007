import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doSearchProfile } from '@app/store/slices/search/searchProfileSlice';
import { Spin, Tabs, TabsProps } from 'antd';
import styled from 'styled-components';
import TabButton from '@app/components/dashboards/profile-info/components/components/Buttons/TabButton/TabButton';
import StickyBox from 'react-sticky-box';
import GeneralInformationSEO from '@app/components/dashboards/demo-profile-info/components/GeneralInformationSEO';
import VacanciesSEO from '@app/components/dashboards/demo-profile-info/components/VacanciesSEO';
import ResumesSEO from '@app/components/dashboards/demo-profile-info/components/ResumesSEO';
import IceTradesSEO from '@app/components/dashboards/demo-profile-info/components/IceTradesSEO';

enum TABS {
  GENERAL_INFORMATION = '1',
  VACANCIES = '2',
  RESUMES = '3',
  ICE_TRADES = '4',
  GIAS = '5',
  NEWS = '6',
  COMMERCIAL_REGISTER = '7',
  HISTORY = '8',
  GOVERNMENT_INSPECTION = '9',
}

const DemoProfileInfo: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>(TABS.GENERAL_INFORMATION);

  const { unn } = useParams();
  const { loading } = useAppSelector((state) => state.searchProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    try {
      if (typeof unn === 'string') {
        dispatch(doSearchProfile(unn));
      }
    } catch (error) {
      console.log(error);
    }
  }, [unn, dispatch]);

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 8 }}>
      <DefaultTabBar {...props} style={{ background: '#fff' }} />
    </StickyBox>
  );

  return (
    <ProfileContainer>
      {loading ? (
        <SpinnerSpace>
          <Spin size="large" tip="Загрузка данных . . ." />
        </SpinnerSpace>
      ) : (
        <>
          <Tabs defaultActiveKey={TABS.GENERAL_INFORMATION} renderTabBar={renderTabBar}>
            <Tabs.TabPane
              tab={
                <TabButton
                  isActive={activeKey === TABS.GENERAL_INFORMATION}
                  tabKey={TABS.GENERAL_INFORMATION}
                  handleClick={handleTabChange}
                >
                  Основная информация
                </TabButton>
              }
              key={TABS.GENERAL_INFORMATION}
            >
              <GeneralInformationSEO />
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={
                <TabButton
                  isActive={activeKey === TABS.VACANCIES}
                  tabKey={TABS.VACANCIES}
                  handleClick={handleTabChange}
                >
                  Вакансии
                </TabButton>
              }
              key={TABS.VACANCIES}
            >
              <VacanciesSEO />
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={
                <TabButton isActive={activeKey === TABS.RESUMES} tabKey={TABS.RESUMES} handleClick={handleTabChange}>
                  Резюме
                </TabButton>
              }
              key={TABS.RESUMES}
            >
              <ResumesSEO />
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={
                <TabButton
                  isActive={activeKey === TABS.ICE_TRADES}
                  tabKey={TABS.ICE_TRADES}
                  handleClick={handleTabChange}
                >
                  Закупки
                </TabButton>
              }
              key={TABS.ICE_TRADES}
            >
              <IceTradesSEO />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </ProfileContainer>
  );
};

export default DemoProfileInfo;

const ProfileContainer = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: auto;
  padding-top: 5px;
  flex-grow: 1;
`;

const SpinnerSpace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
