import React from 'react';
import styled from 'styled-components';

import * as S from '@app/components/dashboards/profile-info/styles/ProfileInfoStyles';

import { base64Seo } from '@app/components/dashboards/demo-profile-info/data/data';

const ResumesSEO: React.FC = () => {
  return (
    <Container>
      <S.MyDivider />
      <S.Title>Требуемые навыки от специалистов</S.Title>
      <Image src={base64Seo.resumes.skills} alt="table" />

      <Image src={base64Seo.resumes.resumesTable} alt="salary" />

      <S.Title>Детализированная информация</S.Title>
      <Image src={base64Seo.resumes.resumes} alt="salary" />
    </Container>
  );
};

export default ResumesSEO;

const Container = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
`;

const Image = styled.img`
  width: 100%;
`;
