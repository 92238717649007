import React from 'react';
import styled from 'styled-components';

import * as S from '@app/components/dashboards/profile-info/styles/ProfileInfoStyles';

import { base64Seo } from '@app/components/dashboards/demo-profile-info/data/data';

const IceTradesSEO: React.FC = () => {
  return (
    <Container>
      <S.MyDivider />
      <Image src={base64Seo.icetrades.base} alt="base" />
      <Image src={base64Seo.icetrades.sum} alt="sum" />
      <Image src={base64Seo.icetrades.table} alt="table" />
    </Container>
  );
};

export default IceTradesSEO;

const Container = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
`;

const Image = styled.img`
  width: 100%;
`;
